<template>
        <div class="overlay" id="notifymessage" v-if="showModel" style="/* display: none; */">
   <div class="alert alert-dismissible fade show msg mw-90 " :class="bgColor" role="alert">
    <!-- <h4 v-if="showTitle && typeof showTitle === 'boolean'">{{ title }}</h4> -->
      <span>{{ message }}</span>
     <button type="button" class="close p-1" id="closenotifymessage" @click="closeModal()">
       <span aria-hidden="true"><em class="fa fa-times text-secondary"></em></span>
     </button>
   </div>
 </div>
 </template>
 
 <script>
 import { EventBus } from '@/assets/common/EventBus';

 
 export default {
   name: 'AlertModalComponent',
   props: {
    
   },
   data() {
     return {
       showModel:false,
       message:'',
       title:'',
       showTitle:false,
       bgColor:'alert-success'
     };
   },
   methods: {
     openModal() {
       this.showModel = true
      
     },
     closeModal() {
       if (this.showModel) {
           this.showModel = false
       }
     },
     
     
     // Set alert type class (success, danger, warning)
     setAlertType(type) {
    switch (type) {
        case 'success':
            this.bgColor = 'alert-success'
            break;
        case 'danger':
            this.bgColor = 'alert-danger'
            break;
        case 'failure':
            this.bgColor = 'alert-danger'
            break;
        case 'warning':
            this.bgColor ='alert-warning'
            break;
        default:
            this.bgColor ='alert-success'
            break;
    }
},
     showAlertDialog({message, type, showTitle, title, isHide }) {
       this.setAlertType(type)
       this.message = message;
       this.showTitle = showTitle;
       this.title = title;
       this.openModal();
       // Auto-hide alert after a delay if needed
      if (isHide || type === "success") {
        setTimeout(() => {
          this.closeModal();
        }, 3000);
      }
     },
   },
   created() {
     EventBus.$on('show-alert-dialog', this.showAlertDialog);
   },
   beforeDestroy() {
     EventBus.$off('show-alert-dialog', this.showAlertDialog);
   },
 };
 </script>

 