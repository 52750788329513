import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
function loadLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}
// Set default language
loadLocale('en');
// Install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});
extend('url', {
  validate(value) {
    if (value) {
      return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/.*)?$/.test(value);
    }
    return false;
  },
  message: 'The {_field_} field must be a valid URL',
});
extend('colorCode', {
  validate(value) {
    if (value) {
      return /^#[0-9A-F]{6}$/.test(value);
    }
    return false;
  },
  message: 'The {_field_} field must be a valid color',
});
extend('ZipCode', {
  validate(value) {
    if (value) {
      return /(^\d{6}$)|(^\d{5}-\d{4}$)/.test(value);
    }
    return false;
  },
  message: 'The {_field_} field is not valid',
});
// Install components globally
Vue.component('Form', ValidationObserver);
Vue.component('Field', ValidationProvider);