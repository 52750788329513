import http from "../http-common";
///
/// Data service to the API that will fetch and save records
///
class DataService {
  GetContextMenu(params) {
  return http.instanceCommon.get("/Common/GetContextMenu?"+params);
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async GetDepartmentsAndLocations(params){
    return await http.instanceCommunication.get("/Communication/GetDepartmentsAndLocations?" + params);
  }
  async CompanyContact(params){
    return await http.instanceCommunication.get("/Communication/CompanyContact?" + params);
  }
  GetProjectList(params)
  {
    return http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  NexoCall(params)
  {
    return http.instanceCommon.get("/nexmoCall?"+params)
  }
  VonageCall(params)
  {
    return http.instanceCommon.post("/VonageCall",params)
  }
  GetAllActivity(params)
  { 
    return http.instanceCommunication.get("/Communication/GetAllActivities?"+params)
  }
  async GetUserDetailByPhone(params){
    return await http.instanceCommunication.get("/Communication/GetUserDetailByPhone?" + params);
  }
  async SaveUnifiedContact(params){
    return await http.instanceCommunication.post("/Communication/SaveUnifiedContact" ,params  );
  }
  async GetUnifiedTicketingListing(params,url){
    return await http.instanceCommunication.get(`/Communication/${url}?`+params  );
  }
  async GetUnifiedOrderListing(params,url){
    return await http.instanceCommunication.get(`/Communication/${url}?`+params  );
  }
  async SaveCallMapping(params){
    return await http.instanceCommunication.post(`/Communication/SaveCallMapping` ,params  );
  }
  async getRecentcalls(params)
  {
    return await http.instanceCommunication.get("/Communication/getRecentcalls?" + params  );
  }
  async DropDownForAddComment()
  {
    return await http.instanceCommunication.get("/Communication/DropDownForAddComment"   );
  }
  async DropDownForSubmodule(params)
  {
    return await http.instanceCommunication.get("/Communication/DropDownForSubmodule?" + params  );
  }
  async GetMessagesData(param){
    return await http.instanceCommunication.get("/Messages/Index?" + param  );
  }
  async GetMessageReplyDetail(param){
    return await http.instanceCommunication.get("/Messages/Details?" + param  );
  }
  async LoadProjectUsers(params)
  {
    return await http.instanceCommunication.get("/Messages/UserList?" + params  );
  }
  async PostComment(params)
  {
    return await http.instanceCommunication.post("/Messages/PostComment" , params  );
  }
  async GetAllGroups(params)
  {
    return await http.instanceCommunication.get("/Contacts/GetAllGroups?"+params);
  }
  async GetUserByGroupId(params)
  {
    return await http.instanceCommunication.get("/Contacts/GetUserByGroupId?"+params);
  }
  async updateRoom(params)
  {
    return await http.instanceCommunication.post("/Contacts/updateRoom" ,params);
  }
 
  async fevupdateRoom(params)
  {
    return await http.instanceCommunication.post("/Contacts/updateRoom" ,params);
  }
  async DeleteUserFromGroup(params)
  {
    return await http.instanceCommunication.post("/Contacts/DeleteUserFromGroup" ,params);
  }
  async DeleteGroup(params)
  {
    return await http.instanceCommunication.post("/Contacts/DeleteGroup?" +params);
  }
  async AddTwilio(params)
  {
    return await http.instanceCommunication.post("/Twilio/Add" ,params);
  }
  async AssignAccountKeyAndSecret(params)
  {
    return await http.instanceCommunication.post("/Twilio/AssignAccountKeyAndSecret" ,params);
  }
  async AssignNumberToUser(params)
  {
    return await http.instanceCommunication.post("/Twilio/AssignNumberToUser" ,params);
  }
  async TwilioList(params)
  {
    return await http.instanceCommunication.get("/Twilio/List?" +params);
  }
  async GetTwilioUserConfigDetail()
  {
    return await http.instanceCommunication.get("/Twilio/GetTwilioUserConfigDetail" );
  }
  async DeleteTwilio(params)
  {
    return await http.instanceCommunication.post("/Twilio/Delete?" +params);
  }
  async ChatSetting(params)
  {
    return await http.instanceCommunication.post("/Twilio/SaveUserChatSetting" ,params);
  }
  async GetUserChatSetting()
  {
    return await http.instanceCommunication.get("/Twilio/GetUserChatSetting" );
  }
  async GetDDLData(params)
  {
    return await http.instanceCommon.get("/Common/GetDDLData?" + params  );
  }
  async DeleteMessage(params)
  {
    return await http.instanceCommunication.get("/Messages/Delete?" + params  );
  }
  async ShareMessage(params)
  {
    return await http.instanceCommunication.get("/Messages/ShareMessage?" + params  );
  }
  GetBlobUrl(){  
    return http.instanceCommon.get("/Common/GetBlobUrl" );
  }
  async PostMessage(params)
  {
    return await http.instanceCommunication.post("/Messages/PostMessage" , params  );
  }
  async EditMessage(params)
  {
    return await http.instanceCommunication.get("/Messages/EditMessage?" + params  );
  }
  // NexoCall(params)
  // { 
  //   return http.instanceCommon.get("/nexmoCall?"+params)
  // }
  DownloadAttachment(params) {
    return http.instanceCommunication.get("/Messages/DownloadAttachment?" + params);
  }
  GetConfigurationValueByKey(params) {
    return http.instanceCommunication.get("/Messages/GetConfigurationValueForCompanyByKey?" + params);
  }
  async SaveChatFileIinAzure(params)
  {
    return await http.instanceCommunication.post("/Communication/SaveChatFileIinAzure" ,params);
  }
  async ContactFavouriteUpdate(params)
  {
    return await http.instanceCommunication.post("/Contacts/ContactFavouriteUpdate?" +params);
  }
  async GetUcaasContactsByGroup(params)
  {
    return await http.instanceCommunication.get("/Contacts/GetUcaasContactsByGroup?" +params);
  }
  
  async UploadstreamNew(params)
  {
    
    return await http.instancebaseDocStorage.post("/UploadStreamNew" ,params);
  }
  async DownloadFileSingle(params)
  {
    
    return await http.instancebaseDocStorage.get("/DownloadFileSingle?"+ params,{responseType:"blob"});
  }
  async insertnotifications(params){ 
  try {

    console.log('Sending notification params:', params); // Log params for debugging
        await http.instanceCommunication.post("/Notification/InsertNotification", params);
        console.log('Notification inserted successfully');
      
  } 
  catch (error) 
  {
      console.error('Error inserting notification:', error);
  }
  }
  GetConfigDataValue(params){
    return http.instancebaseDocStorage.get("/GetConfigDataValue?"+params);
  }
  GetFileType(params){
  
    return http.instancebaseDocStorage.get("/GetFileTypes?"+params);
  }
  async ManageDocUserStorageSettingData(params) {
    return http.instanceCommon.post("/Common/ManageDocUserStorageSettingData", params);
  }
  GetUserStorage(params){
    return http.instancebaseDocStorage.get("/GetUserStorage");
  }
}
export default new DataService();
