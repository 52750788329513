import axios from "axios";
import { useloggedUserInfo } from "./stores/UserInfo";
// Our APIGATEWAY URL

const baseDocStorage = process.env.VUE_APP_DOCSTORAGE_API;
const baseDocStorageUrl =`${baseDocStorage}`
const baseCommunication = process.env.VUE_APP_COMMUNICATION_API;
const baseCommunicationURL = `${baseCommunication}`
const baseDomain = process.env.VUE_APP_API_URL; 
const baseURL = `${baseDomain}`;
const baseCommonURL = process.env.VUE_APP_COMMON_API;
const baseURLCommon = `${baseCommonURL}`;


const instance = axios.create({
  baseURL,
});
const instanceCommon = axios.create({
    baseURL:baseURLCommon,
  });
const instancebaseDocStorage=axios.create({
    baseURL:baseDocStorageUrl
});
const instanceCommunication=axios.create({
    baseURL:baseCommunicationURL
});
instance.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] =config.url=='/ImportContactsPost' || config.url=='/Index'?"multipart/form-data":  "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommon.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
instancebaseDocStorage.interceptors.request.use(
    (config)=>{
        const token = useloggedUserInfo().GetUserInfo.Token;
        if(token)
        {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommunication.interceptors.request.use(
    (config)=>{
        const token = useloggedUserInfo().GetUserInfo.Token;
        if(token)
        {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] ==config.url=='/MessageBoard' || config.url=='/Index' ?"multipart/form-data": "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default {instance,instanceCommon,instancebaseDocStorage,instanceCommunication};
