<template>
<div :class="imageUperDivClass ? imageUperDivClass :'chat-user-img  align-self-center me-3 ms-0'" @click="$emit('click')">
    <img v-if="image" :src="image" :class="imageClass ? imageClass : 'rounded-circle avatar-xs'" alt="">
    <div v-else :class="spanUperDivClass ? spanUperDivClass :'avatar-xs'" >
        <span :class="spanClass ? spanClass :  'avatar-title rounded-circle bg-red-subtle'" class="text-white" :style="{ backgroundColor: getFirstTwoLettersWithColor(name).color }">
            {{ getFirstTwoLettersWithColor(name).letters}}
        </span>
    </div>
    <slot></slot>
</div>
</template>

  
<script>
import {
    getFirstTwoLettersWithColor
} from "@/assets/common/vue-common"
export default {
    
    props: {
        imageUperDivClass: {
            type: String,
        },
        imageClass: {
            type: String,
        },
        spanUperDivClass: {
            type: String,
        },
        spanClass: {
            type: String,
        },
        name:{
            type: String,
        },
        image:{
            type: String,
        }
    },
    setup() {

return {
    getFirstTwoLettersWithColor
}
}
}
</script>
