import { useloggedUserInfo } from "@/stores/UserInfo";
import { storeToRefs } from "pinia";
import moment from 'moment';
import { EventBus } from './EventBus'
import DataService from "@/services/DataService";
let mobile = false;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    mobile = true; 
}
let isReponsive = false;
if (window.innerWidth <= 1024) {
    isReponsive = true;
}
String.prototype.format = function () {
    let args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
};

let configCount=0;
export default {

    data: function () {
        return {
            NoteListType: 'card',
            chkidCommonForVueCommon: 0
        }
    },
    setup(){ 
        const store = useloggedUserInfo();
        const { GetUserInfo } = storeToRefs(store)
        if(GetUserInfo && GetUserInfo.value){
            var filename = '';
            switch(GetUserInfo.value.UserType){
                case "CA":
                    filename =    '/Content/Tourguide/CA/tourguide.js';
                    break;
                case "NU":
                    filename =    '/Content/Tourguide/NU/tourguide.js';
                    break;
                case "PM":
                   filename =    '/Content/Tourguide/CA/tourguide.js';
                    break;   
            }
            if(configCount==0){
           
            let js = document.createElement('script');
            js.setAttribute("type","text/javascript");
            js.setAttribute("src", filename);           
            document.getElementsByTagName("head")[0].appendChild(js);  
            configCount+=1;
            }
        }
        return { GetUserInfo }
    },
    methods: {
        formatDate: function (value) {
            if (value) {
                return moment(String(value), "YYYY-MM-DD")
            }
        },
       
        confirmR: function(message, showtitle, title, okAction, cancelAction) {             
            if (showtitle) {
                EventBus.$emit('show-confirm-dialog', {
                    message,
                    showtitle,
                    title,
                    okAction,
                    cancelAction,
                  });

                // talygen.confirm({
                //     title: title,
                //     message: message,
                //     callback: function (result) {
                //         if (result) {
                //             okAction();
                //         }
                //         else {
                //             if (cancelAction != undefined)
                //                 cancelAction();
                //         }
                //     }
                // });
            }
            // else {
            //     talygen.confirm(message, function (result) {
            //         if (result) {
            //             okAction();
            //         }
            //     });
            // }
        },
         /**
          * javascript popup
          * @param {*} message 
          * @param {*} type 
          * @param {*} showtitle 
          * @param {*} title 
          * @param {*} isHide 
          * @returns 
          */
         
        ShowAlert(message, type, showTitle, title, isHide) {
            
            EventBus.$emit('show-alert-dialog', {
                message,
                type,
                showTitle,
                title,
                isHide,
              });
            // let vm  = this;
            // const notifyMessageDiv = document.querySelector("div#notifymessage");
            // if (!notifyMessageDiv) return;
        
            // notifyMessageDiv.style.display = "inline-flex";
            // const msgDiv = notifyMessageDiv.querySelector(".msg");
            // vm.resetClasses(msgDiv, ["alert-success", "alert-danger", "alert-warning"]);
        
            // const hdrDiv = notifyMessageDiv.querySelector(".hdr");
            // const isHeader = !!hdrDiv;
        
            // if (isHeader) {
            //     vm.resetClasses(hdrDiv, ["alert-success-header", "alert-danger-header", "alert-warning-header"]);
            //     if (showtitle) {
            //         const hdrTitle = hdrDiv.querySelector("h4");
            //         hdrTitle.innerHTML = title;
            //         hdrTitle.style.display = "none";
            //     }
            // }
        
            // vm.setAlertType(msgDiv, hdrDiv, type, isHeader, message);
        
            // if (isHide || type === "success") {
            //     setTimeout(() => {
            //         notifyMessageDiv.style.display = "none";
            //     }, 3000);
            // }
        
            // document.body.addEventListener("click", function(event) {
            //     if (event.target.closest("#closenotifymessage")) {
            //         vm.closeNotifications()
            //     }
            // });
        },
         resetClasses(element, classes) {
            classes.forEach(cls => element.classList.remove(cls));
        },
         setAlertType(msgDiv, hdrDiv, type, isHeader, message) {
            const lowerType = type.toLowerCase();
            if (lowerType === "success") {
                if (isHeader) hdrDiv.classList.add("alert-success-header");
                msgDiv.classList.add("alert-success");
            } else if (lowerType === "warning") {
                if (isHeader) hdrDiv.classList.add("alert-warning-header");
                msgDiv.classList.add("alert-warning");
            } else {
                if (isHeader) hdrDiv.classList.add("alert-danger-header");
                msgDiv.classList.add("alert-danger");
            }
            msgDiv.querySelector("span:first-child").innerHTML = message;
        },
        closeNotifications() {
            const notifyMessageDiv = document.getElementById("notifymessage");
            if (notifyMessageDiv) {
                notifyMessageDiv.style.display = "none";
                const spanElement = notifyMessageDiv.querySelector(".msg span:first-child");
                if (spanElement) {
                    spanElement.innerHTML = '';
                }
            }
        }
    }
} 
//get date globale date format
export  const getDateInFormat = (date) => {
    return moment(new Date(date), "DD/MM/YYYY HH:MM:SS").format(dateTimeFromat);
}; 
export  const getDateOnly = (date) => {
    return moment(new Date(date), "DD/MM/YYYY HH:MM:SS").format(dateFromat);
};
export const dateTimeFromat = "YYYY-MM-DD HH:mm:ss"
export const dateFromat = "YYYY-MM-DD"
export const  secondsToHms = (secs) =>{
    if (secs) {
        var sec_num = parseInt(secs, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    } else {
        return ''
    }
}
// Get Today Yesterday and date
export const getPreviousDay = (date = moment()) => {
    const getDate = moment(new Date(date),'DD/MM/YYYY').format('YYYY-MM-DD')
    const Yesterday = moment(moment(),dateTimeFromat).subtract(1, 'day').format('YYYY-MM-DD')
    const today = moment(moment(),dateTimeFromat).endOf('day').format('YYYY-MM-DD')
    if (getDate == today) return 'Today'
    if (getDate == Yesterday) return 'Yesterday'
    return moment(new Date(date),'DD/MM/YYYY').format('DD, MMM YYYY')
}
export const dateAndTimeZone = (date,format=dateTimeFromat)=>{
    const store = useloggedUserInfo();
    const { GetUserInfo } = storeToRefs(store)
    let newDate = moment.utc(date).format()
    let timezonedate = moment(newDate).utcOffset(GetUserInfo.value.TimeZone).toString()
    return moment(new Date(timezonedate),dateTimeFromat).utcOffset(GetUserInfo.value.TimeZone).format(format)
}
export const timeStamp = (date)=>{
    let currentDate = new Date(date);
    let timestamp = currentDate.getTime();
    return timestamp
}
export const getRandomColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        // Ensure the value is dark by capping it
        value = Math.floor(value * 0.5); // Multiplying by 0.5 ensures the color stays within a dark range
        color += ('00' + value.toString(16)).slice(-2);
    }
    return color;
}

export const getFirstTwoLettersWithColor = (contactName) => {
    const nameToUse = (typeof contactName === 'undefined') ? 'Unknown' : contactName;
    const firstTwoLetters = nameToUse.substring(0, 2);
    const color = getRandomColor(nameToUse);
    return {
        letters: firstTwoLetters,
        color: color
    };
}

export const documentIcon = (extension) =>{
    return {
        '.pdf': 'fa fa-file-pdf-o',
        '.xls': 'fas fa-file-excel',
        '.xlsx': 'fas fa-file-excel',
        '.doc': 'fas fa-file-word',
        '.docx': 'fas fa-file-word',
        '.ppt': 'fas fa-file-powerpoint',
        '.pptx': 'fas fa-file-powerpoint',
        '.txt': 'fas fa-file-alt',
        '.csv': 'fas fa-file-csv',
        '.zip': 'fas fa-file-archive',
        '.rar': 'fas fa-file-archive',
        '.7z': 'fas fa-file-archive',
        '.html': 'fas fa-file-code',
        '.css': 'fas fa-file-code',
        '.cpp': 'fas fa-file-code',
        '.h': 'fas fa-file-code',
        '.sh': 'fas fa-file-code',
        '.md': 'fas fa-file-alt',
        '.rtf': 'fas fa-file-alt',
        '.svg': 'fas fa-file-image',
        '.jfif': 'fas fa-file-image'
    }[extension];
}

export const checkPrivilege = async (controllerName, actionName,vm) => {

        const { data } = await DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`);
        if (!data) {
            
            vm.ShowAlert(vm.$t('UnAuthorized'), "warning", true, "Alert");
            return false;
        } else {
            return true;
        }
};