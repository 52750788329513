import Vue from 'vue'
import './set-public-path'
import App from './App.vue'
import VueI18n from "vue-i18n";
import EN from "./localization/en";
import router from "./router";
import VueSocketIO from 'vue-socket.io';
//import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Multiselect from 'vue-multiselect'
import commonMixin from '../src/assets/common/vue-common.js'
// import ModuleHeader from '../src/components/Header/ModuleHeader.vue';
import breadcrumb from './components/Common/BreadCrumb.vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useloggedUserInfo } from '../src/stores/UserInfo';
//import VueCompositionAPI from '@vue/composition-api'
import singleSpaVue from 'single-spa-vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Loader from './components/Common/Loader.vue'
import moment from 'moment';
import { EventBus } from '@/assets/common/EventBus';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../src/assets/libs/magnific-popup/magnific-popup.css';
import '../src/assets/libs/owl.carousel/assets/owl.carousel.min.css';
import '../src/assets/libs/owl.carousel/assets/owl.theme.default.min.css';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/icons.min.css';
import '../src/assets/css/app.min.css';
import '../src/assets/libs/simplebar/simplebar.min.js';
import VueChatScroll from 'vue-chat-scroll'
import { ValidationProvider } from 'vee-validate';
import VueLazyLoad from 'vue-lazyload'
import ImageORColor from '@/components/Common/ImageOrColor.vue'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
Vue.use(VueLazyLoad)
// import './assets/base.css'
Vue.config.productionTip = false;
require('./vee-validate');
 //Vue.use(VueCompositionAPI)
 Vue.use(VueI18n);
 Vue.use(PiniaVuePlugin);
 Vue.use(VueChatScroll)
//  Vue.component('v-date-picker', DatePicker)
 Vue.component('loader', Loader)
 Vue.component('ImageORColor', ImageORColor)
 Vue.component('breadcrumb', breadcrumb);
//  Vue.component('ModuleHeader',ModuleHeader);
 Vue.component('Multiselect', Multiselect);
 Vue.component('ValidationProvider', ValidationProvider);
 Vue.use(VueTelInput);
 const pinia=createPinia();
 window.pinia = pinia;
 Vue.use(pinia);
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en:EN,
  },
});
Vue.mixin(commonMixin);
Vue.filter('formatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'))
}
})
Vue.filter('formatTime', function (value){
  if (value) {
    return moment(value, "HH:mm:ss").format(String('h:mm A'));
}
})
Vue.filter('formatDateTime', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format(String('MM/DD/YYYY h:mm A'))
}
})
let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation()
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName]
          // See if this excluded element
          // is the same element the user just clicked on
          if (Array.isArray(excludedEl)) {
            // If it's an array, use `includes`
            clickedOnExcludedEl = excludedEl.includes(e.target);
        } else if (excludedEl instanceof Element) {
            // If it's a single DOM element, use `contains`
            clickedOnExcludedEl = excludedEl.contains(e.target);
        }
        }
      })
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]()
      }
    }
    // Register click/touchstart event listeners on the whole page
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },
  unbind () {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})
Vue.directive('draggable',{
  bind(el) {
    el.style.position = 'absolute';
    el.onmousedown = function (e) {
      // Calculate the offset position
      const offsetX = e.clientX - el.offsetLeft;
      const offsetY = e.clientY - el.offsetTop;

      function onMouseMove(e) {
        el.style.left = `${e.clientX - offsetX}px`;
        el.style.top = `${e.clientY - offsetY}px`;
      }

      // Add the event listeners
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', function onMouseUp() {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      });
    };
  }
})
Vue.filter('currency', function (value){
  if (value) {
    var n = Number.parseFloat(value);
    if (!value || isNaN(n) || n < 0) return 0;
    return n.toFixed(2);
} else {
    return value;
}
})
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text;
  }
});
const containerSelector = '#page-2-container'
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    pinia,
    i18n,
    router,
    render(h) {     
      return h(App, {
         props: {          
          props: {          
            userProfile:useloggedUserInfo().setUser(this.userProfile) 
           } 
        } 
      });//
    },
    el: containerSelector
    
  }
});
Vue.use(new VueSocketIO({
  connection: process.env.VUE_GET_CHAT_URL
}));

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
