<template>
     <!-- delete Modal -->
     <div class="modal  d-block" v-if="showModel" id="deleteModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
				   <div class="modal-head p-3 pb-0">
						<h4 class="font-size-15" style="float: left; margin-bottom: 0px;">Delete Message?</h4>
                        <button
              type="button"
              class="btn-close"
              @click="closeModal"
              aria-label="Close"
              style="float: right;"
            ></button>
                   </div>
				   <div class="modal-body">
						<div class="chat-avatar checkmessage">
                                 
								<label for="del1">{{ modalMessage }}</label>      
                           </div>
                    </div>
				    <div class="modal-footer border-0 pt-0">
						<a  class="btn btn-default"  @click="cancel">Cancel</a>
						<a  class="btn btn-primary" @click="confirm">{{ modalTitle }} </a>
						
					</div>
               </div>
            </div>
         </div>
         <!-- end modal -->

  </template>
  
  <script>
  import { EventBus } from '@/assets/common/EventBus';
 
  
  export default {
    name: 'ConfirmModalComponent',
    props: {
     
    },
    data() {
      return {
        modalInstance: null,
        okAction: null,
        cancelAction: null,
        showModel:false,
        message:'',
        title:'Confirm',
        showTitle:true
      };
    },
    methods: {
      openModal() {
        this.showModel = true
       
      },
      closeModal() {
        if (this.showModel) {
            this.showModel = false
        }
      },
      confirm() {
        if (this.okAction) this.okAction();
        this.closeModal();
      },
      cancel() {
        if (this.cancelAction) this.cancelAction();
        this.closeModal();
      },
      showConfirmDialog({ message, showTitle, title, okAction, cancelAction }) {
        this.modalMessage = message;
        this.showTitle = showTitle;
        this.modalTitle = title;
        this.okAction = okAction;
        this.cancelAction = cancelAction;
        this.openModal();
      },
    },
    created() {
      EventBus.$on('show-confirm-dialog', this.showConfirmDialog);
    },
    beforeDestroy() {
      EventBus.$off('show-confirm-dialog', this.showConfirmDialog);
    },
  };
  </script>
  
  <style scoped>
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
  }
  </style>
  