import Vue from 'vue'
import VueRouter from 'vue-router'
import { useloggedUserInfo } from "../stores/UserInfo";
import Unauthorized from '../views/auth/Unauthorized.vue'
import DataService from "../services/DataService";
import  authService   from '../services/authservice';
Vue.use(VueRouter)
const routes = [
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/Communication/Index",
        name: "Communication",
        component: () => import("../views/Communication/Index.vue"),
        meta: {
            actionName: 'Index',
            controllerName:'Communication',
        }
      },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
}) 
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'none';
    if (to.path == '/unauthorized') {
        //authService.logOut();
        next()
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        if (app) {
            let isCheckPrivilege = true;
            if(to.meta.hasOwnProperty('checkPrivilege'))
            {
                isCheckPrivilege = to.meta.checkPrivilege;
            }
            if(isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else{
                    let pathArray = to.path.split("/");
                    if(pathArray.length > 2){
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else{
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if(controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next()
            }
        }
        else {
            authService.authenticate(to.path).then(() => {
                console.log("authenticating a protected url:" + to.path);
                next();
              });
        }
    }
});
export default router

